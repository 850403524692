export enum PlansStateEnum {
  NSW = "NSW",
  QLD = "QLD",
  SA = "SA",
  VIC = "VIC",
  NT = "NT",
  WA = "WA",
  ACT = "ACT",
  TAS = "TAS",
}
