function getBodyScrollTop() {
  return (
    self.pageYOffset ||
    (document.documentElement && document.documentElement.scrollTop) ||
    (document.body && document.body.scrollTop)
  );
}
const easeOutCubic = (t: number) => --t * t * t + 1;

export const scrollTo = (
  element: Element,
  offset = 0,
  duration = 600
): void => {
  if (element) {
    const yPos =
      getBodyScrollTop() + element.getBoundingClientRect().top - offset;
    const startY = window.scrollY;
    const difference = yPos - startY;
    const startTime = performance.now();

    const step = () => {
      const progress = (performance.now() - startTime) / duration;
      const amount = easeOutCubic(progress);
      window.scrollTo({ top: startY + amount * difference });
      if (progress < 0.99) {
        window.requestAnimationFrame(step);
      }
    };

    step();
  }
};
