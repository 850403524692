import { Ref } from "vue";
import { POSITION, ToastInterface } from "vue-toastification";
import { Store } from "vuex";
import {
  AnalyticsDataInterface,
  AnalyticsDataItem,
} from "@/types/AnalyticsDataInterface";
import { formatDateYYYYMMDD } from "@/helpers/date";

export const getAnalyticsData = async (
  startDate: Date | string,
  endDate: Date | string,
  DataForAnalytics: AnalyticsDataInterface,
  DataFromAnalytics: Ref<AnalyticsDataItem[]>,
  loading: Ref<boolean>,
  store: Store<any>,
  toast: ToastInterface,
): Promise<void> => {
  loading.value = true;

  const startDateSelected = new Date(startDate);
  const endDateSelected = new Date(endDate);

  const formatStartDateByAnalytics = formatDateYYYYMMDD(startDateSelected);
  const formatEndDateByAnalytics = formatDateYYYYMMDD(endDateSelected);

  DataForAnalytics.dateRanges = [
    {
      startDate: formatStartDateByAnalytics,
      endDate: formatEndDateByAnalytics,
    },
  ];

  try {
    const res = await store.dispatch(
      "analytics/createReport",
      DataForAnalytics
    );
    DataFromAnalytics.value = res.data[0].rows;
    loading.value = false;
  } catch (error) {
    let message = "Error getting data from Google Analytics";
    if ((error as Error).message.includes("429")) {
      message =
        "Too many requests, please wait a moment before getting a new report";
    }

    toast.error(message, {
      position: POSITION.TOP_LEFT,
    });
    loading.value = false;
  }
};
