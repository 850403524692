export function getKeysWithSameValue(
  values: string[],
  keys: string[] = ["label", "value"]
) {
  return values.map((value) => {
    return keys.reduce((acc, key) => {
      return Object.assign(acc, { [key]: value });
    }, {});
  });
}
