import { AnalyticsDataInterface } from "@/types/AnalyticsDataInterface";

export const dataLotsOnClick: AnalyticsDataInterface = {
  dateRanges: [{ startDate: "30daysAgo", endDate: "yesterday" }],
  metrics: [{ name: "eventCount" }],
  dimensions: [
    { name: "eventName" },
    { name: "pageTitle" },
    { name: "customEvent:lot_title" },
    { name: "customEvent:stage_title" },
  ],
  dimensionFilter: {
    filter: {
      fieldName: "eventName",
      stringFilter: {
        matchType: "EXACT",
        value: "lotOnClick",
      },
    },
  },
  orderBys: [
    {
      dimension: { orderType: "ALPHANUMERIC", dimensionName: "pageTitle" },
      desc: false,
    },
  ],
  keepEmptyRows: true,
};

export const dataSiteVisitors: AnalyticsDataInterface = {
  dateRanges: [{ startDate: "30daysAgo", endDate: "yesterday" }],
  metrics: [{ name: "sessions" }],
  dimensions: [{ name: "pageTitle" }],
  orderBys: [
    {
      dimension: { orderType: "ALPHANUMERIC", dimensionName: "pageTitle" },
      desc: false,
    },
  ],
  keepEmptyRows: true,
};

export const dataReferrer: AnalyticsDataInterface = {
  dateRanges: [{ startDate: "30daysAgo", endDate: "yesterday" }],
  metrics: [{ name: "sessions" }],
  dimensions: [{ name: "pageTitle" }, { name: "sessionSource" }],
  orderBys: [
    {
      dimension: { orderType: "ALPHANUMERIC", dimensionName: "pageTitle" },
      desc: false,
    },
  ],
  keepEmptyRows: true,
};

export const dataAverageSessionDuration: AnalyticsDataInterface = {
  dateRanges: [{ startDate: "30daysAgo", endDate: "yesterday" }],
  metrics: [{ name: "averageSessionDuration" }],
  dimensions: [{ name: "pageTitle" }],
  orderBys: [
    {
      dimension: { orderType: "ALPHANUMERIC", dimensionName: "pageTitle" },
      desc: false,
    },
  ],
  keepEmptyRows: true,
};

export const ANALYTICS_REPORT_MIN_DATE = '2015-08-14';
