import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-290cc7dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-wrapper scroll-wrapper" }
const _hoisted_2 = { class: "modal-title modal-title-change-lot" }
const _hoisted_3 = { class: "modal__title" }
const _hoisted_4 = { class: "bold" }
const _hoisted_5 = { class: "content-block scroll-wrapper" }
const _hoisted_6 = { class: "content-map" }
const _hoisted_7 = { class: "modal-content-map" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["Modal"], {
    "is-open": $setup.props.isOpen,
    onClose: $setup.onClose,
    classes: "lots-map-modal height-limited"
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createTextVNode("This home design is suitable for "),
            _createElementVNode("span", _hoisted_4, _toDisplayString($setup.props.lotsByHouseDesigns.length) + " lots.", 1),
            _createTextVNode(" Select your preferred lot.")
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              ($setup.viewTypeMap === 'estate')
                ? (_openBlock(), _createBlock($setup["EstateMap"], {
                    key: 0,
                    role: $setup.UserRoles.CUSTOMER,
                    viewType: $setup.viewType,
                    isEmbedded: $setup.isEmbedded,
                    mapPage: $setup.MapPages.ESTATE_MAP,
                    builderView: true,
                    lotsByHouseDesigns: $setup.props.lotsByHouseDesigns,
                    onChangeViewMap: $setup.toggleViewTypeMap
                  }, null, 8, ["role", "viewType", "isEmbedded", "mapPage", "lotsByHouseDesigns"]))
                : _createCommentVNode("", true),
              ($setup.viewTypeMap === 'stage' && $setup.isDesktop)
                ? (_openBlock(), _createBlock($setup["StageMap"], {
                    key: 1,
                    role: $setup.UserRoles.CUSTOMER,
                    viewType: $setup.viewType,
                    isEmbedded: $setup.isEmbedded,
                    mapPage: $setup.MapPages.STAGE_MAP,
                    builderView: true,
                    lotsByHouseDesigns: $setup.props.lotsByHouseDesigns,
                    actualStageQuote: $setup.actualStageQuote,
                    onSelectLot: $setup.onReserve,
                    onChangeViewMap: $setup.toggleViewTypeMap
                  }, null, 8, ["role", "viewType", "isEmbedded", "mapPage", "lotsByHouseDesigns", "actualStageQuote"]))
                : _createCommentVNode("", true),
              ($setup.viewTypeMap === 'stage' && !$setup.isDesktop)
                ? (_openBlock(), _createBlock($setup["StageList"], {
                    key: 2,
                    viewType: 'list',
                    isEmbedded: $setup.isEmbedded,
                    mapPage: $setup.MapPages.STAGE_LIST,
                    estate: $setup.props.estateInfo,
                    isBuilderView: true,
                    isMapModalView: true,
                    lotsByHouseDesigns: $setup.props.lotsByHouseDesigns,
                    onSelectLot: $setup.onReserve
                  }, null, 8, ["isEmbedded", "mapPage", "estate", "lotsByHouseDesigns"]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["is-open"]))
}