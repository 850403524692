import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "spinner"
}
const _hoisted_2 = {
  key: 1,
  class: "reports-analytics"
}
const _hoisted_3 = {
  key: 0,
  class: "reports-by-number"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        ($setup.filteredDataFromAnalytics.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode($setup["ReportsByNumber"], {
                title: 'Conversions (visitors --> leads)',
                number: $setup.conversionRateOrZero,
                type: $setup.ReportsTitleByNumber.PERCENTAGE
              }, null, 8, ["title", "number", "type"])
            ]))
          : _createCommentVNode("", true)
      ]))
}