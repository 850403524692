import { ReportsTimePeriod } from "@/enums/ReportsTimePeriod";

interface DateRange {
  startDate: string;
  endDate: string;
}

export const calculateDateRange = (
  periodSelected: ReportsTimePeriod
): DateRange => {
  const today = new Date();
  const startDate = new Date(today);
  const endDate = today.toISOString().substring(0, 10);

  switch (periodSelected) {
    case ReportsTimePeriod.LAST_WEEK: 
      startDate.setDate(today.getDate() - 7);
      break;
    case ReportsTimePeriod.LAST_MONTH:
      startDate.setMonth(today.getMonth() - 1);
      break;
    default:
      startDate.setMonth(today.getMonth() - 1);
      break;
  }

  return { startDate: startDate.toISOString().substring(0, 10), endDate };
};
