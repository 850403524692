import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79dfe7ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "customer-card-empty"
}
const _hoisted_2 = { class: "wrapper-icon" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.props)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: require(`@/${$setup.props.imgPath}`)
          }, null, 8, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, _toDisplayString($setup.props.message), 1)
      ]))
    : _createCommentVNode("", true)
}