import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b0ef6fa4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-waitlist" }
const _hoisted_2 = { class: "card-waitlist-wrapper-image" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "card-waitlist-info" }
const _hoisted_5 = { class: "card-waitlist-info__estate" }
const _hoisted_6 = { class: "card-waitlist-info__size" }
const _hoisted_7 = { class: "card-waitlist-info__budget" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: $setup.props.data.estateId.logoUrl
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString($setup.props.data.estateId.title), 1),
      _createElementVNode("div", _hoisted_6, _toDisplayString($setup.getSize), 1),
      _createElementVNode("div", _hoisted_7, _toDisplayString($setup.getBudget), 1)
    ])
  ]))
}