import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ef6fafc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "spinner"
}
const _hoisted_2 = {
  key: 1,
  class: "reports-analytics"
}
const _hoisted_3 = {
  key: 0,
  class: "reports-by-number"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        ($setup.filteredDataFromAnalytics.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode($setup["ReportsByNumber"], {
                title: 'Website Visits',
                number: $setup.ReportFromAnalyticsNumber
              }, null, 8, ["number"])
            ]))
          : _createCommentVNode("", true)
      ]))
}