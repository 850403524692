export function getListedColor(index: number) {
  const colorsList = [
    "#a129ff",
    "#eeae2b",
    "#a5d6c5",
    "#49c1ee",
    "#f12b5b",
    "#FB7534",
    "#2ccd3c",
    "#595983",
  ];
  return index >= colorsList.length
    ? colorsList[
        index - Math.floor(index / colorsList.length) * colorsList.length
      ]
    : colorsList[index];
}
