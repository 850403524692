import { LotMinimalInterface } from "@/types/LotInterface";
import { LotRangeInterface } from "@/types/LotRangeInterface";

const maxSizeValue = 5000;
const maxFrontageValue = 50;
const maxBudgetValue = 1500000;

export function getRanges(lotsArray: LotMinimalInterface[]): LotRangeInterface {
  const minSize = Math.min(...lotsArray.map((item) => item.size));
  const maxSize = Math.max(...lotsArray.map((item) => item.size));
  const minFrontage = Math.min(...lotsArray.map((item) => item.blockWidth));
  const maxFrontage = Math.max(...lotsArray.map((item) => item.blockWidth));
  const minBudget = Math.min(...lotsArray.map((item) => item.price));
  const maxBudget = Math.max(...lotsArray.map((item) => item.price));
  return {
    size: {
      min: checkNumberMin(minSize),
      max: checkNumberMax(maxSize, maxSizeValue),
    },
    frontage: {
      min: checkNumberMin(minFrontage),
      max: checkNumberMax(maxFrontage, maxFrontageValue),
    },
    budget: {
      min: checkNumberMin(minBudget),
      max: checkNumberMax(maxBudget, maxBudgetValue),
    },
  };
}

function checkNumberMin(number: number) {
  return Math.floor(Number.isFinite(number) ? number : 0);
}
function checkNumberMax(number: number, maxValue: number) {
  return Math.ceil(Number.isFinite(number) ? number : maxValue);
}
