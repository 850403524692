import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a8ce37f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-lot" }
const _hoisted_2 = { class: "card-lot-wrapper-image" }
const _hoisted_3 = { class: "image-wr" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "card-lot-info" }
const _hoisted_6 = { class: "card-lot-info__estate" }
const _hoisted_7 = { class: "card-lot-info__id" }
const _hoisted_8 = { class: "card-lot-info__size" }
const _hoisted_9 = { class: "card-lot-info__price" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: $setup.props.data.estate.logoUrl
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["card-lot-info__status badge card-lot-info__status--mobile", $setup.getValueByStatus($setup.props.data.status, 'background')])
      }, _toDisplayString($setup.getValueByStatus($setup.props.data.status, "badgeLabel")), 3)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, _toDisplayString($setup.props.data.estate.title), 1),
      _createElementVNode("div", _hoisted_7, "Lot " + _toDisplayString($setup.props.data.title), 1),
      _createElementVNode("div", _hoisted_8, _toDisplayString($setup.props.data.size), 1),
      _createElementVNode("div", _hoisted_9, _toDisplayString($setup.props.data.price), 1),
      _createElementVNode("div", {
        class: _normalizeClass(["card-lot-info__status badge", $setup.getValueByStatus($setup.props.data.status, 'background')])
      }, _toDisplayString($setup.getValueByStatus($setup.props.data.status, "badgeLabel")), 3)
    ])
  ]))
}