export const optionsForHorizontalBarchart = {
  responsive: true,
  indexAxis: "y",
  barThickness: 20,
  borderSkipped: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      }
    },
    yAxes: [{
      beginAtZero: true,
      grid: {
        display: false,
      },
      ticks: {
        display: true,
        position: "right",
      },
    }],
  },
  elements: {
    bar: {
      borderWidth: 0,
      borderRadius: 4,
    },
  },
};

export const optionsBarChartForWebsiteAnalytics = {
  responsive: true,
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        color: (context: { tick: { value: number; }; }) => context.tick.value === 0 ? "#8f00ff" : "#c4c4c4",
        lineWidth: (context: { tick: { value: number; }; }) => context.tick.value === 0 ? 3 : 1,
        display: true,
        drawBorder: false,
        drawTicks: false,
      },
      ticks: {
        display: true,
        padding: 22,
      },
    },
  },
  plugins: {
    title: {
      display: false,
    },
  },
};

export const optionsBarChartForLeadsReport = {
  responsive: true,
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        color: (context: { tick: { value: number; }; }) => context.tick.value === 0 ? "#8f00ff" : "#c4c4c4",
        lineWidth: (context: { tick: { value: number; }; }) => context.tick.value === 0 ? 3 : 1,
        display: true,
        drawBorder: false,
        drawTicks: false,
      },
      ticks: {
        display: true,
        padding: 22,
      },
    },
  },
  plugins: {
    title: {
      display: false,
    },
  },
};

export const optionsForBarchart = {
  responsive: true,
  scales: {
    y: {
      beginAtZero: true,
    },
  },
  layout: {
    autoPadding: false,
    padding: {
      left: 0,
      right: 0,
      top: 10,
      bottom: 0,
    },
  },
  plugins: {
    legend: {
      position: "center",
      align: "start",
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 20,
      },
      labels: {
        useBorderRadius: true,
        borderRadius: 6,
      },
    },
  },
};

export const optionsPieChart = {
  responsive: true,
  plugins: {
    legend: {
      position: "left",
      align: "start",
      padding: {
        left: 20,
        right: 0,
        top: 0,
        bottom: 0,
      },
      labels: {
        useBorderRadius: true,
        borderRadius: 6,
      },
    },
  },
  aspectRatio: 2.5,
  cutout: 60,
};

export const optionsPieChartBuyerLocation = {
  ...optionsPieChart,
};

export const backgroundColorForBuyerLocation = ["#800080", "#8f00ff", "#ab52c5", "#e7b0f8", "#a16ae8"];
export const backgroundColorForBuyerLocationBar = ['#bb66ff', '#dfa6f0', '#cc97dc', '#b266b2', '#dfa6f0'];
export const backgroundColorForBuyerType = ["#50bf62", "#008000"];
export const backgroundColorForLeadsReport = ["#38b6ff", "#cb6ce6", "#ffbd59", "#7ed957"];
export const backgroundColorForLots = ["#595983", "#2dcd3d", "#4ac1ee", "#455cfc", "#feb51f", "#8f00ff", "#f12b5b", "#fb7535", "#c4c4c4"];
export const chartBackgroundColor = ["#8f00ff"];
export const chartBackgroundColorForLeadsReport = ["#81d0ff", "#d9a4f0", "#f7d39b", "#abe59a"];
export const chartBackgroundColorForLots = ["#969398", "#7edc8a", "#90d5f4", "#8d98fd", "#fcd29b", "#b961ff", "#f47b9c", "#faa785", "#d9d6db"];
export const chartBackgroundColorForWebsiteAnalytics = ["#f3d39b", "#7bceff", "#af61ff", "#75dc8a", "#ea7b9c"];
export const BackgroundColorForWebsiteAnalyticsLots = ["#feb51f", "#38b6ff", "#8f00ff", "#2dcd3d", "#f12b5b"];
