import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-843c4496"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "spinner"
}
const _hoisted_2 = {
  key: 1,
  class: "reports-eois"
}
const _hoisted_3 = { class: "reports-container-bar-chart" }
const _hoisted_4 = {
  key: 0,
  class: "bar-chart-vertical"
}
const _hoisted_5 = {
  key: 1,
  class: "reports-by-number"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          ($setup.eoisByEstate && $setup.props.role !== 'landowner')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode($setup["ChartForReports"], {
                  labels: $setup.eoisByEstate.status,
                  setData: $setup.eoisByEstate.quantity,
                  backgroundColor: $setup.chartBackgroundColor,
                  title: 'EOIs submitted',
                  chartType: 'bar',
                  options: $setup.optionsForBarchart
                }, null, 8, ["labels", "setData", "backgroundColor", "options"])
              ]))
            : _createCommentVNode("", true),
          ($setup.eoisByEstate && $setup.props.role === 'landowner')
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode($setup["ReportsByNumber"], {
                  title: 'Total EOIs',
                  number: $setup.eoisByEstate.total
                }, null, 8, ["number"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
}