import { getKeysWithSameValue } from "@/helpers/getKeysWithSameValue";
import { CustomerDocumentType } from "@/enums/CustomerDocument";

export const alertMessages = {
  success: ["Document successfully uploaded!"],
  warning: [
    "Please choose a type for the document",
    "Please select a file",
    "Invalid file format. Only 'jpg', 'jpeg', 'png', 'heic', 'pdf' are allowed",
    "File size must be less than 26 mb",
  ],
};

export const documentTypes = getKeysWithSameValue(
  Object.values(CustomerDocumentType)
);
