import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b9b8d69a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.builder)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        class: "builder-logo",
        src: $setup.builder.logo
      }, null, 8, _hoisted_1))
    : _createCommentVNode("", true)
}