import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["span", $setup.props.isActive ? 'dots-active' : 'dots'])
  }, [
    ($setup.props.isActive)
      ? _withDirectives((_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "actions",
          style: _normalizeStyle($setup.props.position)
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 4)), [
          [$setup["vClickOutside"], $setup.clickOutside]
        ])
      : _createCommentVNode("", true)
  ], 2))
}