import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b173250"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "page-wr"
}
const _hoisted_2 = {
  key: 1,
  class: "title-main"
}
const _hoisted_3 = { class: "title-buttons-wr" }
const _hoisted_4 = {
  key: 0,
  class: "card"
}
const _hoisted_5 = {
  key: 0,
  class: "card-title-toggle"
}
const _hoisted_6 = { class: "title-wrapper" }
const _hoisted_7 = { class: "card-title-bold title" }
const _hoisted_8 = {
  key: 0,
  class: "toggle-wrapper"
}
const _hoisted_9 = { class: "form-wrapper" }
const _hoisted_10 = { class: "block-wrapper" }
const _hoisted_11 = { class: "actions-wrapper" }
const _hoisted_12 = {
  key: 1,
  class: "spinner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!$setup.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["title-main-wr", {'preview-center' : $setup.isPreviewActive}])
        }, [
          ($setup.isPreviewActive)
            ? (_openBlock(), _createBlock($setup["EOIRequestForm"], {
                key: 0,
                estateId: $setup.estateId,
                eoiTemplate: $setup.form,
                isPreview: true
              }, null, 8, ["estateId", "eoiTemplate"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, "Edit Form Fields")),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "button default white-bg",
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.isPreviewActive = !$setup.isPreviewActive))
            }, _toDisplayString(!$setup.isPreviewActive ? 'Preview' : 'Exit Preview'), 1),
            (!$setup.isPreviewActive)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "button default primary",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.save()))
                }, "Save & Publish"))
              : _createCommentVNode("", true)
          ])
        ], 2),
        (!$setup.isPreviewActive)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getGroups, (group, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "card-wrapper",
                  key: index
                }, [
                  (group.groupName !== 'default_group')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("div", _hoisted_7, _toDisplayString($setup.capitalizeString(group.groupName)), 1)
                        ]),
                        (!group.isRequired)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                              _createVNode($setup["ToggleButton"], {
                                additionalClass: 'large',
                                modelValue: group.isSelectedFields,
                                "onUpdate:modelValue": ($event: any) => ((group.isSelectedFields) = $event),
                                onChange: ($event: any) => ($setup.switchToggle(group.isSelectedFields, index))
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("form", {
                      class: "default-form form",
                      onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.fields, (field) => {
                        return (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createVNode($setup["CheckboxInput"], {
                            label: $setup.checkboxLabelText(field.fieldName),
                            modelValue: field.isRequired ? true : field.isActive,
                            disabled: field.isRequired,
                            class: _normalizeClass($setup.getCheckboxClasses(field.fieldName)),
                            "onUpdate:modelValue": ($event: any) => ((field.isActive = !field.isActive), $setup.checkIsSelectedFields(group))
                          }, null, 8, ["label", "modelValue", "disabled", "class", "onUpdate:modelValue"])
                        ]))
                      }), 256))
                    ], 32)
                  ])
                ]))
              }), 128)),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", {
                  class: "button default",
                  onClick: $setup.setDefaultTemplate
                }, "Set as Default"),
                _createElementVNode("div", {
                  class: "button default",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.router.push({ name: 'EOI' })))
                }, "Cancel")
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
    : ($setup.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12))
      : _createCommentVNode("", true)
}