import { ref } from "vue";
import { useRouter } from "vue-router";
import store from "@/store";
import LocalStorageService from "@/services/localStorage.service";
import { RouteLandingNames } from "@/enums/RouteLandingNames";
import { UserRoles } from "@/enums/UserRoles";
import getUserRole from "@/helpers/getUserRole";
import { AuthParams } from "@/types";
import { UseAuthReturnInterface } from "@/types/UseAuthReturnInterface";

export const useAuth = (): UseAuthReturnInterface => {
  const router = useRouter();
  const isOpenedLoginModal = ref(false);
  const loginError = ref("");
  const isOpenedResetPasswordModal = ref(false);
  const forgotPasswordMessage = ref("");
  const clearForm = ref(false);
  const { isSalesPersonRole } = getUserRole();

  const onCloseLoginModal = () => {
    isOpenedLoginModal.value = false;
    loginError.value = "";
  };

  const signIn = (data: AuthParams) => {
    const { isCustomer } = router.currentRoute.value.params;

    loginError.value = "";
    store
      .dispatch("user/login", data)
      .then((response) => {
        onCloseLoginModal();
        if (response.data.user.role === UserRoles.CUSTOMER || isCustomer) {
          router.push("/customer");
        } else if (
          isSalesPersonRole.value &&
          !response.data.user.detailsId?.estateIds.length
        ) {
          router.push({ name: "LandownerProfile" });
        } else {
          router.push("/dashboard");
        }
      })
      .catch((error) => {
        if (error.response?.data?.statusCode === 403) {
          LocalStorageService.set("urlAfterVerify", "/dashboard");
          router.push({
            name: "RegistrationVerifyView",
            params: { userEmail: data.email.toLowerCase() },
          });
        } else {
          loginError.value = error.response?.data?.message;
        }
      });
  };

  const onForgotPassword = () => {
    onCloseLoginModal();
    isOpenedResetPasswordModal.value = true;
  };

  const onSendInResetPasswordModal = (email: string) => {
    forgotPasswordMessage.value = "";
    store
      .dispatch("user/forgotPassword", email.toLowerCase())
      .then(() => {
        onCloseResetPasswordModal();
        clearForm.value = true;
      })
      .catch((error) => {
        clearForm.value = false;
        forgotPasswordMessage.value = error.response?.data?.message;
      });
  };

  const onCloseResetPasswordModal = () => {
    forgotPasswordMessage.value = "";
    isOpenedResetPasswordModal.value = false;
  };

  return {
    isOpenedLoginModal,
    loginError,
    isOpenedResetPasswordModal,
    forgotPasswordMessage,
    clearForm,
    onCloseLoginModal,
    signIn,
    onForgotPassword,
    onSendInResetPasswordModal,
    onCloseResetPasswordModal,
  };
};
