import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-855b28d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "spinner"
}
const _hoisted_2 = {
  key: 1,
  class: "list-houses"
}
const _hoisted_3 = {
  key: 2,
  class: "no-results"
}
const _hoisted_4 = {
  key: 3,
  class: "content-button-load"
}
const _hoisted_5 = {
  key: 4,
  class: "spinner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.loading && !$setup.props.houses)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    ($setup.props.houses && $setup.props.houses.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.displayedHouses, (house, index) => {
            return (_openBlock(), _createBlock($setup["HouseCard"], {
              key: index,
              house: house,
              estate: $setup.props.estate,
              quote: $setup.props.quote,
              result: $setup.props.result,
              houseDetailsView: $setup.props.houseDetailsView,
              onOnClickHouse: $setup.onClickHouse
            }, null, 8, ["house", "estate", "quote", "result", "houseDetailsView"]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, "No data to display")),
    ($setup.hasNextBatch && !$setup.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("button", {
            class: "load-more-btn",
            onClick: $setup.loadMore
          }, "Load more")
        ]))
      : _createCommentVNode("", true),
    ($setup.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5))
      : _createCommentVNode("", true)
  ], 64))
}