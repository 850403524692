import { delay } from "@/helpers/async";
import { Ref } from "vue";

const scrollToBottomModal = async (
  modalContainerRef: Ref,
  modalBodyRef: Ref
): Promise<void> => {
  await delay(10);

  if (!modalBodyRef.value || !modalContainerRef.value) return;

  modalBodyRef.value.scrollTo({
    top: modalContainerRef.value.scrollHeight,
    behavior: "smooth",
  });
};

export default scrollToBottomModal;
