import { onBeforeUnmount, watch, Ref } from "vue";

export default function (isOpen: Ref<boolean>, close: () => void): void {
  watch(
    () => isOpen.value,
    () => {
      if (isOpen.value) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    },
    { immediate: true }
  );

  const keyPress = (event: KeyboardEvent) => {
    if (event && event.code === "Escape") {
      close();
    }
  };
  document.addEventListener("keydown", keyPress);

  onBeforeUnmount(() => {
    document.removeEventListener("keydown", keyPress);
    document.body.classList.remove("no-scroll");
  });
}
