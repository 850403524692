import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b97e348a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropdown-multiple" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["DropDown"], { onClose: $setup.onClose }, {
    control: _withCtx(() => [
      _createElementVNode("button", {
        class: _normalizeClass(["input dropdown-input", { 'text-muted': false }]),
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
      }, _toDisplayString($props.modelValue.length > 0 ? $setup.text.join(", ") : " Any"), 1)
    ]),
    dropdown: _withCtx(() => [
      _createElementVNode("ul", _hoisted_1, [
        _createElementVNode("li", null, [
          _createVNode($setup["CheckboxInputMultiple"], {
            onClick: _withModifiers($setup.anyClick, ["prevent","stop"]),
            modelValue: $setup.any,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.any) = $event)),
            value: $setup.anySelectedVariant.value,
            label: $setup.anySelectedVariant.label
          }, null, 8, ["onClick", "modelValue", "value", "label"])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, (item) => {
          return (_openBlock(), _createElementBlock("li", {
            class: _normalizeClass({ disabled: item.disabled })
          }, [
            _createVNode($setup["CheckboxInputMultiple"], {
              onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"])),
              modelValue: $setup.localValue,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.localValue) = $event)),
              value: item.id,
              label: item.title,
              disabled: item.disabled
            }, null, 8, ["modelValue", "value", "label", "disabled"])
          ], 2))
        }), 256))
      ])
    ]),
    _: 1
  }))
}