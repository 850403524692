import axios, { AxiosError } from "axios";
import { POSITION, useToast } from "vue-toastification";
import ErrorMessages from "@/constants/errorsMessages";
import KlaviyoErrorEnum from "@/enums/KlaviyoEnum";

const toast = useToast();

export const handleError = (error: AxiosError): void => {
  if (!axios.isAxiosError(error)) {
    toast.error("Error sending secure package");
    return;
  }
  const errorMsg = error?.response?.data?.message;
  const errorCode = error?.response?.data?.errors?.[0]?.code;

  if (errorMsg === ErrorMessages.ERROR_QUOTE_EMAIL_DUPLICATED_PROFILE) {
    toast.error(ErrorMessages.ERROR_QUOTE_EMAIL_DUPLICATED_PROFILE, {
      position: POSITION.TOP_LEFT,
    });
    return;
  }

  if (errorCode === KlaviyoErrorEnum.DUPLICATE_PROFILE) {
    toast.error(ErrorMessages.ERROR_QUOTE_PHONE_DUPLICATED_PROFILE, {
      position: POSITION.TOP_LEFT,
    });
    return;
  }

  if (errorMsg === ErrorMessages.PROFILE_VERIFY_CONFLICT_ERROR) {
    toast.error(ErrorMessages.PROFILE_VERIFY_CONFLICT_ERROR, {
      position: POSITION.TOP_LEFT,
    });
    return;
  }

  toast.error("There was an error submitting the form", {
    position: POSITION.TOP_LEFT,
  });
};
