import { EstateInterface } from "@/types/EstateInterface";
import { LotInterface } from "@/types/LotInterface";

const setLotEstate = (
  lot: LotInterface,
  estate?: EstateInterface
): LotInterface => {
  if (!estate) {
    console.error("Estate cannot be null");
    return lot;
  }

  return {
    ...lot,
    estateId: estate,
  };
};

export default setLotEstate;
