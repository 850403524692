import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-661e899d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "content-house-name-and-features"
}
const _hoisted_2 = { class: "house-and-features-wr" }
const _hoisted_3 = { class: "house-name" }
const _hoisted_4 = { class: "house-sqm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.props.houseInfo)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString($setup.house.name), 1),
          _createVNode($setup["HouseFeatures"], {
            features: $setup.house,
            directionLogo: true,
            iconSize: 'tiny',
            class: _normalizeClass('house-features')
          }, null, 8, ["features"])
        ]),
        _createElementVNode("div", _hoisted_4, _toDisplayString($setup.formatMeters($setup.house.area, false)) + " sqm", 1)
      ]))
    : _createCommentVNode("", true)
}