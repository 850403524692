import store from "@/store";
import router from "@/router";
import { ref } from "vue";

type AttentionType =
  | "unrelease"
  | "createOptions"
  | "UnreleaseWaitingForApprove"
  | "OptionWaitingForApprove"
  | "";

export const useLotHook = () => {
  const showTransitionModal = ref(false);
  const showReleaseModal = ref(false);
  const showSendContractFormModal = ref(false);
  const attentionType = ref<AttentionType>("");

  function toProfile() {
    showTransitionModal.value = false;
    router.push({ name: "LandownerProfile" });
  }

  return {
    showTransitionModal,
    showReleaseModal,
    attentionType,
    showSendContractFormModal,
    toProfile,
  };
};
