import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76e19307"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "spinner"
}
const _hoisted_2 = {
  key: 1,
  class: "reports-container-graphs"
}
const _hoisted_3 = { class: "title-main" }
const _hoisted_4 = { class: "dropdown-container-reports" }
const _hoisted_5 = {
  class: "dropdown-button input-dropdown",
  type: "button"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "reports-wr-graphs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("h2", _hoisted_3, _toDisplayString($setup.estate?.title), 1),
        _createElementVNode("div", _hoisted_4, [
          _createVNode($setup["DropDown"], { class: "dropdown-container" }, {
            control: _withCtx(() => [
              _createElementVNode("label", _hoisted_5, _toDisplayString($setup.timePeriodSelected), 1)
            ]),
            dropdown: _withCtx(() => [
              _createElementVNode("ul", null, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.optionsTimePeriod, (timePeriod) => {
                  return _createElementVNode("li", {
                    key: timePeriod,
                    onClick: ($event: any) => ($setup.selectTimePeriod(timePeriod)),
                    class: _normalizeClass({ 'dropdown__item-active': timePeriod === $setup.timePeriodSelected })
                  }, _toDisplayString(timePeriod), 11, _hoisted_6)
                }), 64))
              ])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode($setup["AdminReportsByEOIs"], {
            estate: $setup.estate,
            period: $setup.timePeriodSelected
          }, null, 8, ["estate", "period"]),
          _createVNode($setup["AdminReportsByLeads"], {
            estate: $setup.estate,
            period: $setup.timePeriodSelected
          }, null, 8, ["estate", "period"])
        ])
      ]))
}