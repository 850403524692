import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/icon-waitlist.svg'
import _imports_1 from '@/assets/icons/icon-contacts.svg'


const _withScopeId = n => (_pushScopeId("data-v-b4893da8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "reports-number-container" }
const _hoisted_2 = {
  key: 0,
  class: "logo-group",
  src: _imports_0
}
const _hoisted_3 = {
  key: 1,
  class: "logo-group",
  src: _imports_1
}
const _hoisted_4 = { class: "title-report" }
const _hoisted_5 = {
  key: 2,
  class: "number-report"
}
const _hoisted_6 = {
  key: 3,
  class: "number-report"
}
const _hoisted_7 = {
  key: 4,
  class: "number-report"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.props.type === $setup.ReportsTitleByNumber.MINUTES)
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : (_openBlock(), _createElementBlock("img", _hoisted_3)),
    _createElementVNode("h2", _hoisted_4, _toDisplayString($setup.props.title), 1),
    ($setup.props.type === $setup.ReportsTitleByNumber.PERCENTAGE)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_5, _toDisplayString($setup.props.number) + " %", 1))
      : ($setup.props.type === $setup.ReportsTitleByNumber.MINUTES)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_6, _toDisplayString($setup.props.number), 1))
        : (_openBlock(), _createElementBlock("h2", _hoisted_7, _toDisplayString($setup.props.number), 1))
  ]))
}